var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import classNames from 'classnames';
import useClosable from '../_util/hooks/useClosable';
import { withPureRenderTheme } from '../_util/PurePanel';
import { cloneElement } from '../_util/reactNode';
import { ConfigContext } from '../config-provider';
import { RawPurePanel as PopoverRawPurePanel } from '../popover/PurePanel';
import TourPanel from './panelRender';
import useStyle from './style';
const PurePanel = (props) => {
    const { prefixCls: customizePrefixCls, current = 0, total = 6, className, style, type, closable, closeIcon } = props, restProps = __rest(props, ["prefixCls", "current", "total", "className", "style", "type", "closable", "closeIcon"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('tour', customizePrefixCls);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls);
    const [mergedClosable, mergedCloseIcon] = useClosable({
        closable,
        closeIcon,
        customCloseIconRender: (icon) => React.isValidElement(icon)
            ? cloneElement(icon, {
                className: classNames(icon.props.className, `${prefixCls}-close-icon`),
            })
            : icon,
        defaultCloseIcon: React.createElement(CloseOutlined, null),
        defaultClosable: true,
    });
    return wrapCSSVar(React.createElement(PopoverRawPurePanel, { prefixCls: prefixCls, hashId: hashId, className: classNames(className, `${prefixCls}-pure`, type && `${prefixCls}-${type}`, cssVarCls), style: style },
        React.createElement(TourPanel, { stepProps: Object.assign(Object.assign({}, restProps), { prefixCls,
                total, closable: mergedClosable ? { closeIcon: mergedCloseIcon } : undefined }), current: current, type: type })));
};
export default withPureRenderTheme(PurePanel);
